import AgrikoreBg from "../assets/img/agrikore/agrikorebg.png";
import ResearchRoleImg from "../assets/img/agrikore/researchroleimg.png";

// import FpIcon from "../assets/img/agrikore/fpicon.svg";
// import CaIcon from "../assets/img/agrikore/caicon.svg";
// import LpIcon from "../assets/img/agrikore/lpicon.svg";
// import LmIcon from "../assets/img/agrikore/lmicon.svg";

import Research from "../assets/img/agrikore/research.png";
import Processor from "../assets/img/agrikore/processor.png";

import AggregatorOne from "../assets/img/agrikore/aggregatorone.png";
import AggregatorTwo from "../assets/img/agrikore/aggregatortwo.png";
import AggregatorThree from "../assets/img/agrikore/aggregatorthree.png";

import PartnerOne from "../assets/img/agrikore/partnersone.png";
import PartnerTwo from "../assets/img/agrikore/partnerstwo.png";
import PartnerThree from "../assets/img/agrikore/partnersthree.png";

import LoadmastersOne from "../assets/img/agrikore/loadmastersone.png";
import LoadmastersTwo from "../assets/img/agrikore/loadmasterstwo.png";
import LoadmastersThree from "../assets/img/agrikore/loadmastersthree.png";


export const agrikoreMainTitleContent = {
    mainTitle: "Agrikore",
    mainText: "Connect and do business seamlessly in the agriculture ecosystem."
};

export const agrikoreImageContent = {
    sectionImg: AgrikoreBg
};

export const agrikoreOverviewContent = {
        overviewList: [
            {
                ovMainTitle: "Overview",
                workDuration: [
                    {
                        durItem: "2021 – 2022, Freelance Product Designer"
                    }
                ],
                ovMainText: [
                    {
                        oMItem: "Agrikore is a digital marketplace platform for Agriculture. Its unique ecosystem unifies governments, financial institutions, food processors, farmers, logistic companies, e.t.c. At the core is a smart contracting technology supporting an automatic and completely transparent ecosystem."
                    }
                ],
            },
            {
                ovMainTitle: "THE CHALLENGE",
                workDuration: null,
                ovMainText: [
                    {
                        oMItem: "Before Agrikore, smallholder farmers across Africa faced limited access to financing, inconsistent market connections, and inefficient supply chains. Middlemen often controlled pricing, and a lack of transparency led to losses for both farmers and buyers."
                    }
                ],
            },
            {
                ovMainTitle: "MY APPROACH",
                workDuration: null,
                ovMainText: [
                    {
                        oMItem: "I designed Agrikore to be a decentralized, secure, and automated platform that digitizes the entire agricultural value chain. By leveraging blockchain technology, Agrikore ensures trust and transparency, enabling seamless transactions and smart contracts between all stakeholders."
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "Design Leadership"
            },
            {
                rolItem: "Design Research"
            },
        ],
        recognition: null
};

export const agrikoreDetailsContent = [
    {
        detailsTitle: "The research",
        paragraphList: [
            {
                subTitle: null,
                problemStatement: null,
                subText: [
                    {
                        stItem: "The research phase had a dual objective: to gain comprehensive insights into the agricultural marketplace and to understand the perspectives of different stakeholders along the value chain. This involved an exhaustive approach, combining desk research and user surveys conducted in collaboration with the Operations team."
                    },
                    {
                        stItem: "The research findings were used to create a set of well-informed recommendations for creating product features and user flows, serving as a solid foundation for future product development in the dynamic agricultural sector."
                    }
                ],
            }
        ],
        role: null,
        roleImg: ResearchRoleImg,
        detailsFullSegmentImage: true,
        detailsSectionImage: Research,
        detailsImages: [],
        breakdownSegment: true,
        breakdownMainTitle: "Key Features & Solutions",
        breakdownSegmentItems: [
            {
                // breakdownImg: FpIcon,
                breakdownTitle: "Blockchain-Powered Transactions",
                breakdownText: "Using smart contracts, Agrikore eliminates fraud, ensuring secure and verifiable transactions between farmers, suppliers, and buyers.",
            },
            {
                // breakdownImg: CaIcon,
                breakdownTitle: "Seamless Payments & Financial Access",
                breakdownText: "Agrikore integrates mobile money and digital wallets, allowing farmers to receive payments instantly and access loans, subsidies, and insurance products.",
            },
            {
                // breakdownImg: LpIcon,
                breakdownTitle: "Direct Market Access",
                breakdownText: "The platform connects over 7 million farmers with consumer goods companies, reducing reliance on middlemen and improving profitability.",
            },
            {
                // breakdownImg: LmIcon,
                breakdownTitle: "Risk Mitigation & Transparency",
                breakdownText: "Agrikore includes insurance and contract enforcement mechanisms, ensuring that all transactions are honored and reducing risks for financial institutions.",
            },
        ],
        lastItem: false
    },
    {
        detailsTitle: "Food Processor",
        paragraphList: [
            {
                subTitle: "The problem:",
                problemStatement: null,
                subText: [
                    {
                        stItem: "1. Unable to achieve maximum production capacity due to lack of enough farm produce to process."
                    },
                    {
                        stItem: "2. Lack of consistency in the pricing"
                    }
                ],
            },
            {
                subTitle: "The solution:",
                problemStatement: null,
                subText: [
                    {
                        stItem: "I delivered a user experience solution that enabled Food Processors to place orders from one central platform, which would then be published to different community aggregators, who would subsequently aggregate and fulfill them. The solution included a pricing engine that helped determine market prices across the country, ensuring they received fair value for the commodities they were purchasing."
                    }
                ],
            },
        ],
        role: [
            {
                rolItem: "Design Direction"
            },
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "UX/UI Design"
            }
        ],
        detailsFullSegmentImage: true,
        detailsSectionImage: Processor,
        detailsImages: [],
        lastItem: false
    },
    {
        detailsTitle: "Community Aggregator",
        paragraphList: [
            {
                subTitle: "The problem:",
                problemStatement: null,
                subText: [
                    {
                        stItem: "1. Lack of consistency in the pricing for produce and transport."
                    },
                    {
                        stItem: "2. Lacked a way to pay farmers as hard cash was hard to move"
                    }
                ],
            },
            {
                subTitle: "The solution:",
                problemStatement: null,
                subText: [
                    {
                        stItem: "I delivered a streamlined mobile app for Community aggregators that enabled them to accept orders from food processors which they will then aggregate at their warehouse from farmers. They have total transparency of the pricing of the commodity."
                    },
                    {
                        stItem: "The platforms also enable them to be able to pay farmers instantly upon delivery of the produce. CA will then be able to order transport from logistic partners."
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Design Direction"
            },
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "UX/UI Design"
            }
        ],
        detailsFullSegmentImage: false,
        detailsSectionImage: null,
        detailsImages: [
            AggregatorOne, AggregatorTwo, AggregatorThree
        ],
        lastItem: false
    },
    {
        detailsTitle: "Logistic Partners",
        paragraphList: [
            {
                subTitle: "The problem:",
                problemStatement: null,
                subText: [
                    {
                        stItem: "1. Lacked a way to manage their fleet, drivers, and payments."
                    },
                    {
                        stItem: "2. Ability to be able to discover and accept trips that were near their location."
                    }
                ],
            },
            {
                subTitle: "The solution:",
                problemStatement: null,
                subText: [
                    {
                        stItem: "Logistics partners will be able to receive delivery requests from community aggregates near them. They will be able to view the request details and the price for delivering the commodity."
                    },
                    {
                        stItem: "The LP will be able to accept then assign a driver who will pick up and deliver the commodity to the food processor. Once a driver is appointed, they will receive an SMS on their devices of pick up and delivery location details."
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Design Direction"
            },
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "UX/UI Design"
            }
        ],
        detailsFullSegmentImage: false,
        detailsSectionImage: null,
        detailsImages: [
            PartnerOne, PartnerTwo, PartnerThree
        ],
        lastItem: false
    },
    {
        detailsTitle: "Loadmasters",
        paragraphList: [
            {
                subTitle: "The problem:",
                problemStatement: null,
                subText: [
                    {
                        stItem: "1. Needed a way to digitize the waybill submition process."
                    },
                    {
                        stItem: "2. Ability to know how many truck are headed their destination and their current status"
                    }
                ],
            },
            {
                subTitle: "The solution:",
                problemStatement: null,
                subText: [
                    {
                        stItem: "Once the commodity arrives at the Food processor plant, the loadmaster will be able to update the driver has arrived at the location."
                    },
                    {
                        stItem: "He/she will also be able to weigh and inspect the commodity for any spoilt or below quality produce before submitting the waybill on to the platform."
                    },
                    {
                        stItem: "The loadmaster will be able to log how many tracks have arrived and how many have been offload to increase transparency on the platform."
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Design Direction"
            },
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "UX/UI Design"
            }
        ],
        detailsFullSegmentImage: false,
        detailsSectionImage: null,
        detailsImages: [
            LoadmastersOne, LoadmastersTwo, LoadmastersThree
        ],
        lastItem: true
    }
];